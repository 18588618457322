import React from 'react'
import {navigate} from 'gatsby'
import window from 'global'
import Pagination from "react-js-pagination";
import Toast from 'light-toast';

class Pag extends React.Component {
  state = {
    activePage : 1
  }
  componentDidMount(){
    if(typeof window !== `undefined`){
      let path = window.location.pathname;
      path = path.split('/')
      if(path.indexOf('blog') > -1 && path.length === 2){
        this.setState({activePage: 1})
      }
      if(path.indexOf('page') > -1 && path.length === 4){
        this.setState({activePage: +path[3]})
      }
    }
  }
  handlePageChange = (pageNumber) => {
    this.setState({activePage: pageNumber})
    Toast.loading('Loading...');
    setTimeout(()=>{
      Toast.hide();
      if(this.state.activePage === 1){
        navigate('/blog')
        return 
      }
      navigate(`${this.props.base}/${this.state.activePage}`)
    }, 500)
    
  }

  render() {
    return (
      <Pagination
        hideDisabled={true}
        hideFirstLastPages={true}
        firstPageText="‹ First post"
        prevPageText="‹ Previous"
        activePage={this.state.activePage}
        itemsCountPerPage={this.props.perPage}
        totalItemsCount={this.props.total}
        nextPageText="Next ›"
        lastPageText="Last post ›"
        activeLinkClass="active"
        onChange={this.handlePageChange}
      />
    )
  }
}

export default Pag