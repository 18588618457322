import React from 'react'
import Pagination from '../components/commons/pagination'
import PageItem from '../components/blogs/post-item'
import styles from '../components/blogs/listing.module.css'
import Filter from '../components/blogs/filter'
import SEO from '../components/seo'
import DetailsHead from "../components/detailshead"
import Layout from '../components/layout'

const Posts = ({data, pageContext}) =>(
	<Layout>
    <SEO title={`Posts | Car LockSmith Service Provider`}/>
    <DetailsHead title="Blog"/>
    <Filter/>
    <div className={styles.container}>
			<div className={styles.wrapper}>
				<div className={styles.itemsWrapper}>
		      {
		      	data.allStrapiBlogs.edges.map(({node})=>(
		      			<PageItem key={node.id} styles={styles} item={node}/>
		      		)
		      	)
		    	}
		    </div>
          <Pagination
            pageCount={pageContext.numPages}
            currentPage={pageContext.currentPage}
            perPage = {pageContext.limit}
            base="/blog/page"
            total={pageContext.total}
            styles={styles}
          />
		  </div>
		</div>
	</Layout>
)

export default Posts;

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allStrapiBlogs(sort: {fields: created_at, order:DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          created_at
          id
          name
          excerpt
          slug
          feature_image {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
`;