import React from 'react'
import { graphql, StaticQuery, Link } from "gatsby"
import styles from './filter.module.css'
import ArrowUp from '../../images/icon/arrow-up.svg'
import ArrowDown from '../../images/icon/arrow-down.svg'

class FL extends React.Component {
	render (){
		return (
			<>
			<div className={styles.wrapper}>
				<div className={styles.dropdown}>
				  <a className={styles.dropbtn}>Select Category
						<img className={styles.arrowUp} src={ArrowUp} alt={`sks - arrow-up`}/>
						<img className={styles.arrowDown} src={ArrowDown} alt={`sks - arrow-down`}/>
				  </a>

				<StaticQuery
                      query={graphql`
                        query {
                          allStrapiBlogCategories {
	                          edges {
	                            node {
	                              name
	                              slug
	                              id
	                            }
	                          }
	                        }
                        }
                      `}
                      render={data => (
                      	<>
                      	<ul className={styles.dropdownContent}>
                  			<li>
                  				<Link to="/blog">
                  					All
                  				</Link>
                  			</li>
	                      	{
	                      		data.allStrapiBlogCategories.edges.map(({node}, index)=>{
	                      			return(
		                      			<li key={index}>
		                      				<Link to={`/blog/${node.slug}/category`}>
		                      					{node.name}
		                      				</Link>
		                      			</li>
	                      			)
	                      		})
	                      	}
                      	</ul>

                      	</>
                      )}
                    />
				</div>
			</div>
			</>
		)
	}
}

export default FL
